<template>
    <div id="footer">
        <span
            class="record"
            @click.stop="refClick('https://beian.miit.gov.cn/#/Integrated/index')"
        >苏ICP备2021032825号</span>
        Copyright 2016. All rights reversed. Design by no.13
    </div>
</template>

<script>
export default {
    name: "Footer",
    data() {
        return {};
    },
    methods: {
        refClick(url) {
            window.open(url);
        }
    }
};
</script>

<style scoped>
#footer {
    width: 100%;
    height: 82px;
    background: #131313;
    font-size: 16px;
    color: #6a6a6a;
    display: flex;
    align-items: center;
    justify-content: center;
}
.record {
    cursor: pointer;
    margin-right: 50px;
}
</style>
