<template>
    <div id="header">
        <div class="header_body">
            <div class="left">
                <div class="logo">
                    <img class="logo_img" src="../assets/images/logo.png" alt />
                </div>
            </div>
            <div class="right">
                <div
                    class="navs clickBgNo"
                    :class="navsIndex == index ? 'on':''"
                    v-for="(item,index) in navs"
                    :key="index"
                    @click="navsClick(index)"
                >{{item.name}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Header",
    data() {
        return {
            navs: [
                {
                    name: "首页"
                },
                {
                    name: "浏览资讯"
                },
                {
                    name: "下载APP"
                },
                {
                    name: "查看小程序"
                }
            ],
            navsIndex: 0
        };
    },
    methods: {
        navsClick(index) {
            if(index == 1) {
                window.open('https://www.aladingziben.com/web');
            } else if (index == 2 || index == 3) {
                this.$parent.popupFun(index);
            }
        }
    }
};
</script>

<style scoped>
#header {
    width: 100%;
    height: 96px;
    background: #f9f9fa;
}
.header_body {
    width: 1180px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header_body .left {
    display: inline-flex;
    justify-content: space-between;
}
.header_body .left .logo {
    width: 253px;
    height: 75px;
    cursor: pointer;
}
.header_body .left .logo .logo_img {
    width: 100%;
}
.header_body .right {
    display: inline-flex;
    justify-content: space-between;
}
.header_body .right .navs {
    width: 100px;
    height: 46px;
    line-height: 46px;
    font-size: 16px;
    color: #333333;
    cursor: pointer;
    margin-left: 30px;
}
.header_body .right .on {
    background: #116ae3;
    border-radius: 10px;
    color: #ffffff;
}
</style>
